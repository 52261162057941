<template>
	<div class="container Lgmain">
		<div class="header-logo">
			<div @click="logoClick"><img src="../../../static/images/logo3.png"></div>
		</div>
		<div class="login">
			<!-- <div class="login-logo">
				<router-link :to="'/index'">
					<img src="../../../static/images/logo3.png" alt="">
				</router-link>
			</div> -->
			<!-- <div class="login-npgj">
				<h3>耐普矿机</h3>
				<h3>Master Cloud</h3>
			</div> -->
			<div class="from">
				<div class="right">
					<div class="right-naipu">
						<h3>耐普矿机</h3>
						<h3>Master Cloud</h3>
					</div>
					<div class="title">
						<p>您好，</p>
						<h4>欢迎登录</h4>
					</div>
					<div class="from">
						<div class="item" ref="name">
							<div>
								<i class="iconfont iconyonghu-fuben"></i>
								<input type="number" placeholder="请输入账号" v-model="fromData.phone" @input="nameinput"
									@focus="namefocus" @blur="nameblur">
							</div>
							<!-- <div class="codeLogin">
								<i class="iconfont iconyonghu-fuben"></i>
								<input type="number" placeholder="请输入账号" v-model="fromData.phone" @input="nameinput"
									@focus="namefocus" @blur="nameblur">
								<div class="btn round" @click="sendSms()" v-if="!isDisabled">{{content}}</div>
								<div class="btn round wait" v-if="isDisabled">{{content}}</div>
							</div> -->
						</div>
						<div class="tips">{{nameTips}}</div>
						<!-- <div class="item" ref="pass">
							<div>
								<i class="iconfont iconiconfontmima"></i>
								<input type="password" placeholder="请输入密码" v-model="fromData.password"
									@input="passinput" @focus="passfocus" @blur="passblur">
							</div>
						</div>
						<div class="tips">{{passTips}}</div> -->
						<div class="item code" ref="code">
							<div>
								<i class="iconfont iconyanzhengma"></i>
								<input type="number" placeholder="请输入验证码" v-model="fromData.code" @input="codeinput"
									@focus="codefocus" @blur="codeblur">
							</div>
						</div>
						<div class="btn round" ref="codeBtn" @click="sendSms" v-if="!isDisabled">{{content}}</div>
						<div class="btn wait" ref="codeBtn" v-if="isDisabled">{{content}}</div>
						<div class="tips">{{codeTips}}</div>
						<!-- <div class="baocun">
							<el-checkbox v-model="checked">记住账号</el-checkbox>
						</div> -->
						<div class="item submit">
							<el-button type="primary" @click="maskShow">登录</el-button>
						</div>
						<!-- <div class="yzm">
							<span v-if="iscode" @click="qh">账号登录</span>
							<span v-else @click="qh">验证码登录</span>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="login-footer">
			<div class="width-1300">
				<div class="info">
					<div class="footer_left">
						<!-- <div class="footer-logo">
							<router-link :to="'/index'">
								<img src="../../../static/images/logo3.png" alt="">
							</router-link>
						</div> -->
						<div class="left_info">
							<span>网址：<a href="http://www.naipu.com.cn">www.naipu.com.cn</a></span>
							<span>联系电话：400-0793-789</span>
							<span>地址：江西省上饶市上饶经济技术开发区经开大道318号</span>
							<span>Copyright © 2003-2024 All Rights Reserved</span>
              <span><a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备12000001号-6</a></span>
						</div>
					</div>
					<div class="footer_right">
						<div class="item" @click="lianxi">
							<span>联系我们</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="codeGet" v-if="isShow">
			<div class="mask" @click="colse"></div>
			<div class="propo">
				<div class="propo-logo">
					<img src="../../../static/images/logo3.png">
				</div>
				<div class="propo-title">
					<p>录入您的信息，等待专业人员联系您</p>
				</div>
				<div class="form">
					<div class="item">
						<el-input type="text" placeholder="请输入您的姓名"></el-input>
					</div>
					<div class="item">
						<el-input type="text" placeholder="请输入您的手机"></el-input>
					</div>
					<div class="item">
						<el-input type="text" placeholder="请输入您的单位全称"></el-input>
					</div>
					<div class="item">
						<el-input type="text" placeholder="请输入您的部门名称"></el-input>
					</div>
					<div class="item">
						<el-input type="text" placeholder="请填写您的职业"></el-input>
					</div>
				</div>
				<el-button type="primary">提交信息</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookie from 'js-cookie'
	export default {
		data() {
			return {
				imgList: [{
						img: 'cpimg1.jpg',
						url: 'https://www.naipumining.com/'
					},
					{
						img: 'cpimg2.jpg',
						url: 'https://www.naipumining.com/'
					}
				],
				fromData: {
					name: '',
					phone: '',
					password: '',
					code: ''
				},
				iscode: true, // false为默认账号密码登录 true 为 手机验证码登录
				isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
				content: '发送验证码', // 发送验证码按钮的初始显示文字
				timer: null,
				isShow: false,
				nameTips: '',
				passTips: '',
				codeTips: '',
				count: '',
				model: {},
				screenWidth: '',
				codeValue: '',
				checked: true,
			}
		},
		watch: {
			screenWidth: {
				handler() {
					this.getCanvas();
				}
			}
		},
		mounted() {
			//绘制canvas图像
			// this.getCanvas();
			// const that = this
			// window.addEventListener("resize", function() {
			// 	return (() => {
			// 		window.screenWidth = document.body.clientWidth;
			// 		that.screenWidth = window.screenWidth;
			// 	})();
			// });
			//console.log(Cookie.get('frontRemembertoken'));
			if (Cookie.get("frontRemembertoken")) {
				// this.$router.push(localStorage.getItem('routeUrl'));
				this.$router.push('/index');
			}
			if (Cookie.get("userinfo")) {
				let res = JSON.parse(Cookie.get("userinfo"))
				this.fromData.password = res.password;
				this.fromData.phone = res.phone;
			}
		},
		methods: {
			logoClick(){
				if(Cookie.get('frontRemembertoken')){
					this.$router.push('/index')
				}
			},
			lianxi() {
				this.isShow = true;
			},
			colse() {
				this.isShow = false;
			},
			maskShow() {
				// let _this = this;
				// 校验手机号
				if (!this.fromData.phone || this.fromData.phone == '') {
					this.$notify({
						title: '提示',
						message: '请输入手机号',
						type: 'warning'
					});
					return
				}
				if (!(/^1[34578]\d{9}$/.test(this.fromData.phone))) {
					this.$notify({
						title: '提示',
						message: '请输入正确的手机号',
						type: 'warning'
					});
					return
				}
				//if (this.fromData.password == '') {
				//	this.$notify({
				//		title: '提示',
				//		message: '密码不可为空！',
				//		type: 'warning'
				//	});
				//	return
				//}
				if (this.fromData.code == '') {
					this.$notify({
						title: '提示',
						message: '清输入正确的验证码！',
						type: 'warning'
					});
					return
				}
				this.$post('front/login', {
					username: this.fromData.phone,
					password: this.fromData.password,
					code:this.fromData.code
				}).then(res => {
					if (res.data.status == 200) {
						Cookie.set("userinfo", JSON.stringify(res.data.data));
						Cookie.set("frontRemembertoken", res.data.data.remember_token);
						this.$axios.get(this.$store.state.httpUrl + 'front/access/route', {
							headers: {
								'Front-Remember-Token': res.data.data.remember_token
							},
							params: {}
						}).then(resd => {
							if(resd.data.status == 200){
								localStorage.setItem('route',JSON.stringify(resd.data.data.route));
							}
							location.reload(true);
						})
					} else {
						this.$notify({
							title: '提示',
							message: res.data.message,
							type: 'warning'
						});
					}
					//console.log(res);
				})
			},
			toUrl(url) {
				window.open(url);
				// window.location.href = url;
			},
			codefocus() {
				if (this.codeTips == '') {
					// console.log(this.$refs);
					this.$refs.code.style.borderColor = '#0058B1';
					this.$refs.codeBtn.style.borderColor = '#0058B1';
				}
			},
			codeblur() {
				if (this.codeTips == '') {
					this.$refs.code.style.borderColor = '#888';
					this.$refs.codeBtn.style.borderColor = '#888';
				}
			},
			codeinput() {
				if (this.fromData.code.length <= 0) {
					this.$refs.code.style.borderColor = 'red';
					this.$refs.codeBtn.style.borderColor = 'red';
					this.codeTips = '请输入验证码！';
				} else {
					this.$refs.code.style.borderColor = '#0058B1';
					this.$refs.codeBtn.style.borderColor = '#0058B1';
					this.codeTips = '';
				}
				// console.log(this.$refs);
			},
			passfocus() {
				if (this.passTips == '') {
					this.$refs.pass.style.borderColor = '#0058B1';
				}
			},
			passblur() {
				if (this.passTips == '') {
					this.$refs.pass.style.borderColor = '#888';
				}
			},
			passinput() {
				if (this.fromData.password.length <= 0) {
					this.$refs.pass.style.borderColor = 'red';
					this.passTips = '请输入密码！';
				} else {
					this.$refs.pass.style.borderColor = '#0058B1';
					this.passTips = '';
				}
				// console.log(this.$refs);
			},
			namefocus() {
				if (this.nameTips == '') {
					this.$refs.name.style.borderColor = '#0058B1';
				}
			},
			nameblur() {
				if (this.nameTips == '') {
					this.$refs.name.style.borderColor = '#888';
				}
			},
			nameinput() {
				if (!this.iscode) {
					if (this.fromData.name.length <= 0) {
						this.$refs.name.style.borderColor = 'red';
						this.nameTips = '请输入账号！';
					} else {
						this.$refs.name.style.borderColor = '#0058B1';
						this.nameTips = '';
					}
				} else {
					if (this.fromData.phone.length <= 0) {
						this.$refs.name.style.borderColor = 'red';
						this.nameTips = '请输入账号！';
					} else if (!(/^1[34578]\d{9}$/.test(this.fromData.phone))) {
						this.$refs.name.style.borderColor = 'red';
						this.nameTips = '请输入正确的手机号！';
					} else {
						this.$refs.name.style.borderColor = '#0058B1';
						this.nameTips = '';
					}
				}

				// console.log(this.$refs);
			},
			qh() {
				this.iscode = !this.iscode;
				this.$refs.name.style.borderColor = '#888';
				this.$refs.pass.style.borderColor = '#888';
				this.nameTips = '';
				this.passTips = '';
				this.fromData.name = '';
				this.fromData.phone = '';
				this.fromData.password = '';
				this.fromData.code = '';
			},
			sendSms() {
				let vm = this;
				if (this.fromData.phone == '') {
					this.$notify({
						title: '提示',
						message: '请输入手机号',
						type: 'warning'
					});
					return
				}
				if (!(/^1[34578]\d{9}$/.test(this.fromData.phone))) {
					this.$notify({
						title: '提示',
						message: '请输入正确的手机号',
						type: 'warning'
					});
					return
				}
				// 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
				// 控制倒计时及按钮是否可以点击
				this.$post('front/sendSms',{
					phone:this.fromData.phone
				}).then(res =>{
					// console.log(res);
					if(res.data.status == 200){
						this.$notify({
							title: '提示',
							message: '发送成功！',
							type: 'success'
						});
						const TIME_COUNT = 60
						vm.count = TIME_COUNT
						vm.timer = window.setInterval(() => {
							if (vm.count > 0 && vm.count <= TIME_COUNT) {
								// 倒计时时不可点击
								vm.isDisabled = true
								// 计时秒数
								vm.count--
								// 更新按钮的文字内容
								vm.content = vm.count + 's重新获取'
							} else {
								// 倒计时完，可点击
								vm.isDisabled = false
								// 更新按钮文字内容
								vm.content = '重新获取'
								// 清空定时器!!!
								clearInterval(vm.timer)
								vm.timer = null
							}
						}, 1000)
					}else{
						this.$notify({
							title: '提示',
							message: res.data.message,
							type: 'error'
						});
					}
				})
			},
			getCanvas() {
				var canvas = document.getElementById("myCanvas");
				canvas.width = document.documentElement.clientWidth;
				canvas.height = document.documentElement.clientHeight - 4;
				var ctx = canvas.getContext("2d");
				//创建小球的构造函数
				function Ball() {
					this.x = randomNum(3, canvas.width - 3);
					this.y = randomNum(3, canvas.height - 3);
					this.r = randomNum(1, 3);
					this.color = randomColor();
					this.speedX = randomNum(-3, 3) * 0.2;
					this.speedY = randomNum(-3, 3) * 0.2;
				}
				Ball.prototype = {
					//绘制小球
					draw: function() {
						ctx.beginPath();
						ctx.globalAlpha = 1;
						ctx.fillStyle = this.color;
						ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2);
						ctx.fill();
					},
					//小球移动
					move: function() {
						this.x += this.speedX;
						this.y += this.speedY;
						//为了合理性,设置极限值
						if (this.x <= 3 || this.x > canvas.width - 3) {
							this.speedX *= -1;
						}
						if (this.y <= 3 || this.y >= canvas.height - 3) {
							this.speedY *= -1;
						}
					}
				};
				//存储所有的小球
				var balls = [];
				//创建200个小球
				for (var i = 0; i < 150; i++) {
					var ball = new Ball();
					balls.push(ball);
				}
				main();

				function main() {
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					//鼠标移动绘制线
					mouseLine();
					//小球与小球之间自动画线
					drawLine();
					//使用关键帧动画，不断的绘制和清除
					window.requestAnimationFrame(main);
				}
				//添加鼠标移动事件
				//记录鼠标移动时的mouseX坐标
				var mouseX;
				var mouseY;
				canvas.onmousemove = function(e) {
					var ev = event || e;
					mouseX = ev.offsetX;
					mouseY = ev.offsetY;
				}
				//判断是否划线

				function drawLine() {
					for (var i = 0; i < balls.length; i++) {
						balls[i].draw();
						balls[i].move();
						for (var j = 0; j < balls.length; j++) {
							if (i != j) {
								if (Math.sqrt(Math.pow((balls[i].x - balls[j].x), 2) + Math.pow((balls[i].y - balls[j].y),
										2)) < 80) {
									ctx.beginPath();
									ctx.moveTo(balls[i].x, balls[i].y);
									ctx.lineTo(balls[j].x, balls[j].y);
									ctx.strokeStyle = "white";
									ctx.globalAlpha = 0.2;
									ctx.stroke();
								}
							}
						}
					}
				}
				//使用鼠标移动划线
				function mouseLine() {
					for (var i = 0; i < balls.length; i++) {
						if (Math.sqrt(Math.pow((balls[i].x - mouseX), 2) + Math.pow((balls[i].y - mouseY), 2)) < 80) {
							ctx.beginPath();
							ctx.moveTo(balls[i].x, balls[i].y);
							ctx.lineTo(mouseX, mouseY);
							ctx.strokeStyle = "white";
							ctx.globalAlpha = 0.8;
							ctx.stroke();
						}
					}
				}
				//随机函数
				function randomNum(m, n) {
					return Math.floor(Math.random() * (n - m + 1) + m);
				}
				//随机颜色
				function randomColor() {
					return "#fff";
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.Lgmain {
		height: 100vh;
		width: 100%;
		background: url(../../../static/images/loginBg.png) no-repeat center center;
		background-size: cover;
		position: relative;
		@media screen and(min-width:320px) and (max-width:767px) {
			height: auto;
		}
	}

	.header-logo {
		padding: 0 150px;
		background-color: #fff;
		
		img {
			cursor: pointer;
			width: 250px;
			margin-top: 10px;
		}

		@media screen and(min-width:320px) and (max-width:767px) {
			padding: 0 0;
			text-align: center;
			img{
				width: 280px;
			}
		}
	}

	.login {
		text-align: right;
		width: 1300px;
		margin: 0 auto;
		padding-top: 130px;
		box-sizing: border-box;

		@media screen and(min-width:320px) and (max-width:767px) {
			width: auto;
			text-align: center;
			padding-top: 0;
		}

		&-logo {
			width: 800px;
			// background-color: #fff;
			text-align: left;
			padding: 20px 0;
			box-sizing: border-box;

			img {
				width: 200px;
				max-height: 100%;
			}

			.shares {
				display: inline-block;
				color: #ececec;
				font-size: 14px;
				position: relative;
				margin-left: 10px;

				&::after {
					content: '';
					width: 1px;
					position: absolute;
					bottom: 3px;
					top: 3px;
					background-color: #ececec;
				}

				p {
					margin-left: 10px;

					&:last-child {
						letter-spacing: 1px;
					}
				}
			}

			@media screen and(min-width:320px) and (max-width:767px) {
				width: 350px;
			}

			@media screen and(min-width:320px) and (max-width:360px) {
				width: 300px;
			}
		}

		.login-npgj {
			text-align: center;
			position: absolute;
			top: 26%;
			left: 15%;
			margin-top: -114px;
			color: #00337d;
			@media screen and(min-width:320px) and (max-width:767px) {
				position: initial;
				text-align: center;
				margin: 40px 0;
			}
			h3 {
				margin: 0 0;
				font-weight: 500;
				font-size: 50px;
				display: inline-block;
				&:last-child {
					margin-left: 30px;
				}
				@media screen and(min-width:320px) and (max-width:767px) {
					font-size: 24px;
					display: block;
					&:last-child {
						font-size: 24px;
						margin: 10px 0 0;
						padding-left: 0px;
					}
				}
			}
		}

		.from {
			background-color: #fff;
			border-radius: 7px;
			overflow: hidden;
			display: inline-block;
			text-align: left;
			@media screen and(min-width:320px) and (max-width:767px) {
				margin-top: 40px;
			}
			.left {
				display: inline-block;
				vertical-align: middle;
				width: 450px;

				.el-carousel__container {
					height: 450px;
				}

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}

				.el-carousel__button {
					width: 20px;
					background-color: rgba($color: #fff, $alpha: .5);
				}

				.is-active .el-carousel__button {
					background-color: #fff !important;
				}
			}

			.right {
				// height: 450px;
				width: 380px;
				vertical-align: middle;
				display: inline-block;
				padding: 0 30px 30px;
				box-sizing: border-box;

				@media screen and(min-width:320px) and (max-width:767px) {
					height: auto;
					width: 320px;
				}
				.right-naipu{
					margin-top: 30px;
					h3{
						color: #00337d;
						font-weight: 800;
						font-size: 25px;
						margin: 0 0;
						&:last-child {
						}
					}
				}
				.title {
					margin-top: 20px;
					color: #00337D;
					p{
						font-size: 20px;
						@media screen and(min-width:320px) and (max-width:767px) {
							font-size: 16px;
						}
					}
					h4 {
						display: inline-block;
						position: relative;
						padding-bottom: 7px;
						margin: 5px 0 15px;
						font-size: 24px;
						@media screen and(min-width:320px) and (max-width:360px) {
							margin: 0 0;
						}
						@media screen and(min-width:320px) and (max-width:767px) {
							font-size: 20px;
						}
						// &::after {
						// 	position: absolute;
						// 	bottom: -5px;
						// 	left: 9%;
						// 	margin-left: -15px;
						// 	width: 50px;
						// 	border-radius: 10px;
						// 	content: '';
						// 	height: 5px;
						// 	background: linear-gradient(-36deg, #0064C8, #3995F0);
						// }
					}
				}

				.from {
					margin-top: 30px;
					text-align: center;
					width: 320px;

					@media screen and(min-width:320px) and (max-width:767px) {
						// margin-bottom: 30px;
						width: 260px;
					}

					.yzm {
						font-size: 14px;

						span {
							cursor: pointer;
						}
					}

					.tips {
						text-align: left;
						font-size: 14px;
						height: 20px;
						line-height: 25px;
						color: red;
						margin-bottom: 5px;
					}

					.baocun {
						text-align: left;
					}

					.item {
						margin: 0px auto;
						padding: 5px 10px;
						box-sizing: border-box;
						border: 1px solid #888;
						transition: all .3s;
						border-radius: 5px;
						input {
							display: inline-block;
							vertical-align: middle;
							width: 85%;
							border: 0;
							height: 30px;
							outline: none;
							margin-left: 2%;

							//@media screen and(min-width:320px) and (max-width:767px) {
							//	width: 85%;
							//}

							&::-webkit-outer-spin-button,
							&::-webkit-inner-spin-button {
								-webkit-appearance: none;
							}

							&[type="number"] {
								-moz-appearance: textfield;
							}
						}

						.codeLogin {
							input {
								width: 39%;
							}

							.round {
								display: inline-block;
								color: #0064C8;
								width: 35%;
								font-size: 14px;
								margin-left: 30px;
								cursor: pointer;

							}

							.wait {
								display: inline-block;
								width: 35%;
								font-size: 14px;
								cursor: not-allowed;
								color: #E0E0E0;

							}
						}
					}

					.code {
						width: 60%;
						display: inline-block;
						//border-right: 0;


						input {
							width: 75%;
						}

						// @media screen and(min-width:320px) and (max-width:767px) {
						// 	width: 55%;
						// }
					}

					.btn {
						display: inline-block;
						vertical-align: top;
						width: 39.5%;
						height: 45px;
						line-height: 45px;
						font-size: 14px;
						cursor: pointer;
						text-align: center;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
						transition: all .3s;
						// @media screen and(min-width:320px) and (max-width:767px) {
						// 	width: 40%;
						// }
					}

					.round {
						display: inline-block;
						width: 39%;
						font-size: 14px;
						color: #0064C8;
					}

					.wait {
						display: inline-block;
						width: 39%;
						font-size: 14px;
						cursor: not-allowed;
						color: #E0E0E0;
					}

					.submit {
						border: 0;
						padding: 0 0;
						margin-top: 35px;

						.el-button {
							width: 100%;
							border-color: #00337D;
							background: #00337D;
						}
					}
				}
			}
		}
	}

	.codeGet {
		position: fixed;
		bottom: 0;
		left: 0;
		top: 0;
		right: 0;
		z-index: 111;
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (min-width:320px) and (max-width:767px) {
			padding: 0 10px;
		}

		.mask {
			background-color: rgba($color: #000000, $alpha: .5);
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}

		.propo {
			background-color: #fff;
			border-radius: 5px;
			padding: 20px 20px;
			font-size: 18px;
			width: 500px;
			box-sizing: border-box;
			z-index: 112;
			display: inline-block;
			text-align: center;

			.propo-logo {
				margin-bottom: 15px;

				img {
					width: 300px;
					@media screen and (min-width:320px) and (max-width:767px) {
						width: 230px;
					}
				}
			}

			.propo-title {
				color: #000;
				font-size: 14px;
				text-align: left;
				margin-bottom: 20px;
				padding: 0 60px;
				@media screen and (min-width:320px) and (max-width:767px) {
					padding: 0 20px;
				}
			}

			.form {
				width: 80%;
				margin: 0 auto 20px;
				@media screen and (min-width:320px) and (max-width:767px) {
					width: 100%;
				}
				.item {
					box-sizing: border-box;
					margin: 15px;

					.el-input {
						input[type=number] {
							-moz-appearance: textfield;
						}

						input[type=number]::-webkit-inner-spin-button,
						input[type=number]::-webkit-outer-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}
					}
				}
			}

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 320px;
			}

			.el-button {
				width: 50%;
				border-color: #00337D;
				background: #00337D;
			}
		}
	}

	.login-footer {
		background-color: #00337D;
		color: #fff;
		font-size: 12px;
		padding: 15px 0;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;

		@media screen and (min-width:320px) and (max-width:767px) {
			position: initial;
			margin-top: 100px;
		}

		a {
			color: #fff;
			text-decoration: none;
			transition: color .3s;

			// &:hover {
			// 	color: #fff;
			// }
		}

		p {
			margin: 0 0;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.info {
			.footer_left {
				display: inline-block;
				width: 90%;
				vertical-align: middle;
				text-align: left;

				.footer-logo {
					display: inline-block;
					vertical-align: middle;
					margin-right: 15px;

					img {
						width: 250px;
					}

					@media screen and(min-width:320px) and (max-width:767px) {
						display: none;
					}
				}

				.left_info {
					display: inline-block;
					width: 100%;
					vertical-align: middle;

					@media screen and(min-width:320px) and (max-width:767px) {
						width: 100%;
						// margin-top: 15px;
					}
				}

				span {
					margin-right: 15px;
				}

				// p {
				// 	&:last-child {
				// 		span {
				// 			margin-left: 15px;
				// 		}
				// 	}
				// }

				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
					text-align: center;

					span {
						display: block;
						margin-top: 5px;
						margin-left: 0;
					}
				}
			}

			.footer_right {
				display: inline-block;
				vertical-align: middle;
				width: 10%;

				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
				}

				.item {
					display: inline-block;
					width: 100%;
					text-align: right;
					cursor: pointer;
					transition: color .5s;

					@media screen and(min-width:320px) and (max-width:767px) {
						text-align: center;
						margin-top: 15px;
					}

					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
</style>
